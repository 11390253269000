<template>
  <fusioncharts :set="dataSource.pointers.pointer[0].value=dvalue"
                :type="type"
                :width="width"
                :height="height"
                :dataFormat="dataFormat"
                :dataSource="dataSource"
  ></fusioncharts>
</template>
<script>
import Vue from 'vue';
// import {i18n} from "@/main";
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

Vue.use(VueFusionCharts, FusionCharts, FusionTheme);

export default {

  name: "FusionHLinearGauge",

  props: {
    qvalue: {
      type: Number
    },
    dvalue: {
      type: Number
    }
  },

  data: () => ({
    type: "hlineargauge",
    width: "570",
    height: "150",
    dataFormat: "json",

    dialog: false,

    dataSource: {
      chart: {
        "theme": "fusion",
        "bgImage": "/static/thermometer_background.png",
        "bgImageDisplayMode": "stretch",
        "lowerLimit": "-0.5",
        "upperLimit": "1.1",
        "valueFontSize": "11",
        "valueFontBold": "1",
        "showBorder": "0",
        "borderColor": "#000000",
        "minorTMNumber": "5",
        "showValue": "0",
        "valueAbovePointer": "0",
        "pointerOnTop": "1",
        "showTickValues": "1",
        "ticksBelowGauge": "0",
        "majorTMNumber": "17",
        "tickValueDecimals": "1",
        "forceTickValueDecimals": "1",
        "adjustTM": "1",
        "chartLeftMargin": "80",
        "chartRightMargin": "30",
      },
      pointers: {
        pointer: [
          {
            value: '',
          }
        ]
      }
    }

  }),

}

</script>

<style>
text[y="143"] {
  display: none;
}
</style>